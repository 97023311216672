// services/api.methods.js
import makeApiCall from './api.service';
import { formatDate } from '../utils/misc';
import config from "../config";

export const prepareChatHistoryForDB = (chatContent) => {
  // prepare chat history for DB in expected format (same as android)
  const chatHistoryForDB = (chatContent.messages || []).map((message) => ({
    "message": message.message,
    "isUserMessage": message.isUserMessage,
    "imageLocations": message.imageLocations || [],
    "fileNames": message.fileNames || [],
    "aiCharacterName": message.aiCharacterName || "",
    "messageId": message.messageId || 0,
    "apiAIModelName": message.apiAIModelName,
    "dateGenerate": message.dateGenerate ? formatDate(message.dateGenerate) : null,
    "isTTS": message.isTTS || false,
    "showTranscribeButton": message.showTranscribeButton || false,
    "isGPSLocationMessage": message.isGPSLocationMessage || false
  }));
  return chatHistoryForDB;
}

export const triggerAPIRequest = async (endpoint, category, action, userInput, getSettings) => {
  const API_BASE_URL = `${config.apiEndpoint}/${endpoint}`;

  try {
    const apiBody = {
      category: category,
      action: action,
      userInput: userInput,
      userSettings: getSettings(),
      customerId: 1,
    }
    const response = await makeApiCall({
      endpoint: API_BASE_URL,
      method: "POST",
      body: apiBody
    });

    return response;
  } catch (error) {
    console.error('Error triggering DB request:', error);
    throw error;
  }
};

export const triggerStreamingAPIRequest = async (endpoint, category, action, userInput, assetInput, getSettings, { onChunkReceived, onStreamEnd, onError }) => {
  const API_BASE_URL = `${config.apiEndpoint}/${endpoint}`;

  const apiBody = {
    category: category,
    action: action,
    userInput: userInput,
    assetInput: assetInput,
    userSettings: getSettings(),
    customerId: 1,
  };

  try {
    await makeApiCall({
      endpoint: API_BASE_URL,
      method: 'POST',
      body: apiBody,
      streamResponse: true,
      onChunkReceived: onChunkReceived,
      onStreamEnd: onStreamEnd
    });
  } catch (error) {
    onError(error);
    console.error('Error during streaming:', error);
  }
}

export const updateSessionInDB = async (chatContentForSession, sessionId, getSettings, update_last_mod_time_in_db = true) => {
  //db_update_session to DB 
  const chatHistoryForDB = prepareChatHistoryForDB(chatContentForSession);
  const finalInputForDB = {
    "session_id": sessionId,
    "update_last_mod_time_in_db": update_last_mod_time_in_db,
    "chat_history": chatHistoryForDB
  }
  await triggerAPIRequest("api/db", "provider.db", "db_update_session", finalInputForDB, getSettings);
}

export const generateImage = async (image_prompt, getSettings) => {
  try {
    const userInput = { "text": image_prompt };
    const response = await triggerAPIRequest("generate", "image", "generate", userInput, getSettings);
    if (response.success) {
      return response.message.result;
    } else {
      throw new Error('Failed to generate image');
    }
  } catch (error) {
    console.error('Error generating image:', error);
    throw error;
  }
}

export const uploadFileToS3 = async (endpoint, category, action, getSettings, file) => {
  const API_BASE_URL = `${config.apiEndpoint}/${endpoint}`;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('category', category);
  formData.append('action', action);
  formData.append('userInput', JSON.stringify({}));
  formData.append('userSettings', JSON.stringify(getSettings()));
  formData.append('customerId', 1);

  const response = await makeApiCall({
    endpoint: API_BASE_URL,
    method: 'POST',
    body: formData,
    headers: {}, // Ensure headers are set correctly for FormData
  });

  return response;
};

