// hooks/useSettings.js
import { useCallback } from 'react';
import { useCurrentSession } from './useCurrentSession';


import {
  getTextTemperature, getTextModelName, getTextMemorySize, getIsStreamingEnabled, getTextFileAttachedMessageLimit,
  getTTSStability, getTTSSimilarity, getTTSVoice, getTTSStreaming, getTTSSpeed, getTTSModelName,
  getSpeechLanguage, getSpeechTemperature,
  getImageModelName, getImageNumberImages, getImageSize, getImageQualityHD, getImageDisableSafePrompt,
  getUseTestData,
} from '../utils/configuration';


export const useSettings = () => {
  const currentCharacter = useCurrentSession().ai_character_name;

  //return useCallback(() => {
  return useCallback(() => {
    return {
      text: {
        temperature: getTextTemperature(),
        model: getTextModelName(),
        memory_limit: getTextMemorySize(),
        ai_character: currentCharacter,
        streaming: getIsStreamingEnabled(),
        file_attached_message_limit: getTextFileAttachedMessageLimit(),
      },
      tts: {
        stability: getTTSStability(),
        similarity_boost: getTTSSimilarity(),
        voice: getTTSVoice(),
        streaming: getTTSStreaming(),
        speed: getTTSSpeed(),
        model: getTTSModelName(),
      },
      speech: {
        language: getSpeechLanguage(),
        temperature: getSpeechTemperature(),
      },
      image: {
        model: getImageModelName(),
        number_of_images: getImageNumberImages(),
        size_of_image: getImageSize(),
        quality_hd: getImageQualityHD(),
        disable_safe_prompt_adjust: getImageDisableSafePrompt(),
      },
      general: {
        returnTestData: getUseTestData(),
      },
    };
  }, [currentCharacter]);
};
