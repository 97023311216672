import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto', fontFamily: 'Arial, sans-serif' }}>
      <h1>Privacy Policy</h1>
      <p><strong>Last updated: 2024/07/14</strong></p>
      <p>Welcome to "Good to great life". This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our chat application.</p>

      <h2>1. Information We Collect</h2>
      <p>We may collect the following types of information when you use our app:</p>
      <ul>
        <li><strong>User Messages</strong>: All messages, requests, and responses generated by users.</li>
        <li><strong>Usage Data</strong>: Information about how you interact with our app, such as timestamps and usage patterns.</li>
        <li><strong>Device Information</strong>: Information about the device you use to access our app.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li><strong>To Provide and Maintain Our Service</strong>: Ensuring the functionality of the application.</li>
        <li><strong>To Improve Our Service</strong>: Analyzing usage to improve user experience and enhance features.</li>
        <li><strong>To Communicate with You</strong>: Sending updates, notifications, and responding to your inquiries.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We do not share your personal information with third parties, except in the following cases:</p>
      <ul>
        <li><strong>With Your Consent</strong>: We may share your information if you give us explicit permission.</li>
        <li><strong>For Legal Reasons</strong>: If required by law or to protect our rights.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>We use industry-standard security measures to protect your data. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.</p>

      <h2>5. Your Choices</h2>
      <p>You can choose not to provide certain information, but this may affect your ability to use some features of our app.</p>

      <h2>6. GDPR Compliance</h2>
      <h3>Data Controller Information</h3>
      <p>The data controller responsible for your personal data is "Good to great life", located in Lisbon, Portugal.</p>

      <h3>Legal Basis for Processing</h3>
      <p>We process your personal data based on the following legal grounds:</p>
      <ul>
        <li>Your consent.</li>
        <li>The performance of a contract with you.</li>
        <li>Compliance with legal obligations.</li>
        <li>Our legitimate interests.</li>
      </ul>

      <h3>User Rights</h3>
      <p>Under GDPR, you have the following rights:</p>
      <ul>
        <li>The right to access your data.</li>
        <li>The right to rectify inaccurate or incomplete data.</li>
        <li>The right to erasure (the "right to be forgotten").</li>
        <li>The right to restrict processing.</li>
        <li>The right to data portability.</li>
        <li>The right to object to processing.</li>
        <li>The right to withdraw consent at any time.</li>
        <li>The right to lodge a complaint with a supervisory authority.</li>
      </ul>

      <h3>Data Retention</h3>
      <p>We retain your personal data only for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

      <h3>Third-Party Services</h3>
      <p>We may use third-party services for hosting, data processing, and analytics. These third parties may have access to your data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <h2>8. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at support AT goodtogreat.life.</p>
    </div>
  );
}

export default PrivacyPolicy;
