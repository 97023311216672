// BottomToolsMenu.js
import React, { useState, useRef, useEffect, useContext } from 'react';

import { StateContext } from './StateContextProvider';

import './css/BottomToolsMenu.css';
import { useSettings } from '../hooks/useSettings';
import { uploadFileToS3 } from '../services/api.methods';
import ChatCharacters, { filterCharacters, characters } from './ChatCharacters';

import { getSvgIcon } from '../utils/svg.icons.provider';
import { resizeImage } from '../utils/image.utils';

const BottomToolsMenu = ({ handleSendClick, isFloating = false }) => {
  const userInputRef = useRef(null);
  // to control UI while images are being uploaded
  const [uploading, setUploading] = useState(false);
  const [showLocalCharacterSelect, setShowLocalCharacterSelect] = useState(false);
  // used when choosing character after @ is used - this is list of displayed characters based on filters
  const [displayedCharacters, setDisplayedCharacters] = useState(characters);
  // when filtering characters - one will be selected by default - this was done because when hitting enter (when character select view was visible) it was submitting message and not choosing character
  const [selectedCharacterName, setSelectedCharacterName] = useState("Assistant");

  const {
    userInput, setUserInput,
    chatContent, setChatContent, currentSessionIndexRef,
    attachedImages, setAttachedImages,
    attachedFiles, setAttachedFiles,
    editingMessage, isPermanentCharacterChangeSet,
    setIsPermanentCharacterChangeCheckboxVisible, setIsPermanentCharacterChangeSet,
    focusInput, setFocusInput, isMobile,
    isLoading, setErrorMsg, setBottomToolsHeight,
    showHealthOptions, setShowHealthOptions,
    includeHealthData, setIncludeHealthData,
    includeCorrelationData, setIncludeCorrelationData, correlationData
  } = useContext(StateContext);

  const getSettings = useSettings();

  const handleSendButtonClick = () => {
    checkIfCharacterMentioned();

    setShowLocalCharacterSelect(false);
    handleSendClick();
  }

  const handleAttachClick = () => {
    document.getElementById('file-input').click();
  };

  const handleFileChange = async (e) => {
    setErrorMsg("");
    const files = Array.from(e.target.files);
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    const pdfFiles = files.filter(file => file.type === 'application/pdf');

    // Display placeholders
    const placeholders = imageFiles.map(file => ({ file, url: '', placeholder: true }));
    setAttachedImages(prevImages => [...prevImages, ...placeholders]);

    // Display placeholders for PDFs
    const pdfPlaceholders = pdfFiles.map(file => ({ file, url: '', name: file.name, placeholder: true }));
    setAttachedFiles(prevPdfs => [...prevPdfs, ...pdfPlaceholders]);

    setUploading(true);
    for (const imageFile of imageFiles) {
      try {
        const resizedFile = await resizeImage(imageFile);
        const response = await uploadFileToS3("api/aws", "provider.s3", "s3_upload", getSettings, resizedFile);

        if (response.success) {
          const newUrl = response.message.result;
          setAttachedImages(prevImages => prevImages.map(img => img.file === imageFile ? { ...img, url: newUrl, placeholder: false } : img));
        } else {
          setErrorMsg("Problem with file upload. Try again.")
          throw new Error(response.message);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setErrorMsg("Problem with file upload. Try again.")
        setAttachedImages(prevImages => prevImages.filter(img => img.file !== imageFile));
      }
    }

    // Upload PDFs
    for (const pdfFile of pdfFiles) {
      console.log("pdfFile: ", pdfFile)
      try {
        const response = await uploadFileToS3("api/aws", "provider.s3", "s3_upload", getSettings, pdfFile);

        if (response.success) {
          const newUrl = response.message.result;
          console.log("New url: ", newUrl)
          setAttachedFiles(prevPdfs => prevPdfs.map(pdf => pdf.file === pdfFile ? { ...pdf, url: newUrl, placeholder: false } : pdf));

        } else {
          setErrorMsg("Problem with file upload. Try again.")
          throw new Error(response.message);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setErrorMsg("Problem with file upload. Try again.")
        setAttachedFiles(prevPdfs => prevPdfs.filter(pdf => pdf.file !== pdfFile));
      }
    }

    setUploading(false);
  };

  const handleRemoveImage = (index) => {
    setAttachedImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  const handleRemovePdf = (index) => {
    setAttachedFiles(prevPdfs => prevPdfs.filter((_, i) => i !== index));
  };

  const handleInputChange = async (e) => {
    const inputValue = e.target.value;
    setUserInput(inputValue);

    // if @ is used - we trigger character selection view
    if (inputValue.includes("@")) {
      const atIndex = inputValue.lastIndexOf("@");
      const query = inputValue.substring(atIndex + 1).toLowerCase();
      setIsPermanentCharacterChangeCheckboxVisible(true);
      // we can filter out characters by name
      if (query === "") {
        setDisplayedCharacters(characters);
      } else {
        const filtered = filterCharacters(query);
        setDisplayedCharacters(filtered);
      }
    } else { // if there is @ in userInput (for example removed) - hide selection view
      setShowLocalCharacterSelect(false);
    }

    if (inputValue === "") {
      setShowLocalCharacterSelect(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      checkIfCharacterMentioned();
      handleSendClick();
    }
  };

  // to handle ENTER, arrows (when choosing AI character from the list)
  const handleKeyDown = async (event) => {
    if (showLocalCharacterSelect) {
      var currentIndex = displayedCharacters.findIndex(char => char.name === selectedCharacterName);
      // there were some stupid problems - where currentIndex was found as -1
      if (displayedCharacters.length === 1 || currentIndex === -1) currentIndex = 0;

      if (event.key === "Enter" || event.key === 13) {
        event.preventDefault();
        handleCharacterSelect(displayedCharacters[currentIndex]);
      } else if (event.key === "ArrowRight" || event.key === 39) {
        event.preventDefault();
        const nextIndex = (currentIndex + 1) % displayedCharacters.length;
        setSelectedCharacterName(displayedCharacters[nextIndex].name);
      } else if (event.key === "ArrowLeft" || event.key === 37) {
        event.preventDefault();
        const prevIndex = (currentIndex - 1 + displayedCharacters.length) % displayedCharacters.length;
        setSelectedCharacterName(displayedCharacters[prevIndex].name);
      } else if (event.key === "Escape" || event.key === 27) {
        setShowLocalCharacterSelect(false);
      }
    } else if (event.key === "@" || event.key === 50) {
      setShowLocalCharacterSelect(true);
    }
  };


  // used when submit button is clicked or enter is used to submit
  // if @ is used in edited message - we check if there is character mentioned
  const checkIfCharacterMentioned = () => {
    // if it's editing message and there is @ mention of different character - let use set proper one!
    if (editingMessage !== null) {
      const atIndex = userInput.lastIndexOf("@");
      if (atIndex !== -1) {
        // Extract the full character name after the '@' symbol
        const afterAt = userInput.substring(atIndex + 1);

        // Check combinations of 1, 2, or 3 words
        // because character names can be like that
        const words = afterAt.split(/\s+/);
        const potentialNames = [
          words[0],
          words.slice(0, 2).join(' '),
          words.slice(0, 3).join(' ')
        ];

        const character = potentialNames
          .map(name => characters.find(char => char.name.toLowerCase() === name.toLowerCase()))
          .find(char => char); // Find the first matching character

        if (character) {
          // but only if it's not set already (because it should be reset via call.chat.api)
          // there is case where we edit message, mention character via @ and then when send button is used and this function is triggered - it sets original and current to same character
          if (chatContent[currentSessionIndexRef.current].original_ai_character === "")
            handleCharacterSelect(character);
        }
      }
    }
  }

  // executed when character is chosen from the list
  const handleCharacterSelect = (character) => {
    setShowLocalCharacterSelect(false);

    // avoid error -> if @ is used and nothing is chosen
    if (character && character.nameForAPI) {

      const current_ai_character = chatContent[currentSessionIndexRef.current].ai_character_name;
      // set current (main AI character) to temporary variable (so later in ChatHandleAPI we can fallback)
      setChatContent((prevChatContent) => {
        const updatedChatContent = [...prevChatContent];
        updatedChatContent[currentSessionIndexRef.current].ai_character_name = character.nameForAPI;
        // if it's not permanent change - we set original character - so later we can fall back to it
        if (!isPermanentCharacterChangeSet) {
          updatedChatContent[currentSessionIndexRef.current].original_ai_character = current_ai_character;
        }
        // reset the flag
        setIsPermanentCharacterChangeSet(false);

        return updatedChatContent;
      });

      // reset display character (for next execution)
      setDisplayedCharacters(characters);
      setFocusInput(true);
      // set nicely full name of AI character after @
      setUserInput((prevInput) => {
        const cursorPosition = userInputRef.current.selectionStart;
        const atIndex = prevInput.lastIndexOf("@", cursorPosition - 1);
        if (atIndex !== -1) {
          const newText = prevInput.substring(0, atIndex + 1) + character.name + " " + prevInput.substring(cursorPosition);
          return newText;
        }
        return prevInput;
      });
    } else {
      setFocusInput(true);
    }
  };

  // when i'm in text area and i paste image - it should be uploaded as attached one
  const handlePaste = async (e) => {
    const items = e.clipboardData.items;
    let imageFile = null;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        imageFile = items[i].getAsFile();
        break;
      }
    }

    if (imageFile) {
      e.preventDefault();
      setErrorMsg("");

      // Display placeholder
      const placeholder = { file: imageFile, url: '', placeholder: true };
      setAttachedImages(prevImages => [...prevImages, placeholder]);

      setUploading(true);
      try {
        const resizedFile = await resizeImage(imageFile);
        const response = await uploadFileToS3("api/aws", "provider.s3", "s3_upload", getSettings, resizedFile);

        if (response.success) {
          const newUrl = response.message.result;
          setAttachedImages(prevImages => prevImages.map(img =>
            img.file === imageFile ? { ...img, url: newUrl, placeholder: false } : img
          ));
        } else {
          setErrorMsg("Problem with file upload. Try again.")
          throw new Error(response.message);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setErrorMsg("Problem with file upload. Try again.")
        setAttachedImages(prevImages => prevImages.filter(img => img.file !== imageFile));
      }
      setUploading(false);
    }
  };

  // setting height of user input (if more then 1 line)
  useEffect(() => {
    const input = userInputRef.current;
    if (input) {
      input.style.height = 'auto';
      const halfOfScreen = window.innerHeight * 0.5;
      //input.style.height = `${Math.min(input.scrollHeight, halfOfScreen)}px`;
      const newHeight = Math.min(input.scrollHeight, halfOfScreen);
      input.style.height = `${newHeight}px`;
      setBottomToolsHeight(newHeight);
    }

  }, [userInput, setBottomToolsHeight]);

  // make sure that user input is active on load (so we will not need to click on it)
  useEffect(() => {
    if (userInputRef.current) {
      if (!isMobile)
        userInputRef.current.focus();
    }
  }, [isMobile]);
  useEffect(() => {
    if (focusInput && userInputRef.current) {
      // on mobile let's put focus manually
      if (!isMobile)
        userInputRef.current.focus();
      setFocusInput(false);
    }
  }, [focusInput, isMobile, setFocusInput]);

  return (
    <div className={`bottom-tools-menu ${isFloating ? 'floating-bottom-tools-menu' : ''}`}>
      <div className="bottom-tools-menu-characters">
        {showLocalCharacterSelect && <ChatCharacters onSelect={handleCharacterSelect} characters={displayedCharacters} selectedCharacterName={selectedCharacterName} />}
      </div>

      {/* Floating button to show health options (only in Health section ) */}
      {isFloating && !showHealthOptions && (
        <button className="floating-health-options-button" onClick={() => setShowHealthOptions(true)}>
          +
        </button>
      )}

      {/* Health Additional Options Section */}
      {isFloating && showHealthOptions && (
        <div className="health-options-section">
          <div className="health-switch">
            <label>
              <input
                type="checkbox"
                checked={includeHealthData}
                onChange={(e) => {
                  setIncludeHealthData(e.target.checked);
                }}
              />
              Include health data
            </label>
          </div>
          <div className="health-switch">
            <label>
              <input
                type="checkbox"
                checked={includeCorrelationData}
                onChange={(e) => {
                  setIncludeCorrelationData(e.target.checked);
                  if (correlationData === null) {
                    setErrorMsg("No correlation data available. Generate correlations first.");
                    setIncludeCorrelationData(false);
                  }
                }}
              />
              Include correlations
            </label>
          </div>
        </div>
      )}

      <div className="image-preview-container">
        {attachedImages.map((image, index) => (
          <div key={index} className="image-preview">
            {image.placeholder ? (
              <div className="placeholder" />
            ) : (
              <img src={image.url} alt="preview" />
            )}
            <button className="remove-button" onClick={() => handleRemoveImage(index)}>X</button>
          </div>
        ))}
        {attachedFiles.map((pdf, index) => (
          <div key={index} className="image-preview">
            <div className="placeholder">
              <span className="pdfName">{pdf.name}</span>
            </div>
            <button className="remove-button" onClick={() => handleRemovePdf(index)}>X</button>
          </div>
        ))}
      </div>
      <div className="input-container">
        <textarea
          ref={userInputRef}
          className="message-input"
          placeholder="Talk to me..."
          value={userInput}
          onChange={handleInputChange}
          onPaste={handlePaste}
          onKeyPress={handleKeyPress}
          onKeyDown={handleKeyDown}
          rows={1}
          disabled={isLoading}
        />
        <div className="button-container">
          <button className="send-button" onClick={handleSendButtonClick} disabled={isLoading || uploading}>
            {getSvgIcon('buttonSend')}
          </button>
          <button className="attach-button" onClick={handleAttachClick} disabled={isLoading || uploading}>
            {getSvgIcon('buttonAttach')}
          </button>
        </div>
      </div>
      <input
        type="file"
        id="file-input"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="image/*,application/pdf"
        multiple
      />
    </div>
  );
};

export default BottomToolsMenu;
