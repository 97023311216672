// services/call.chat.api.js

import config from '../config';
import { triggerAPIRequest, triggerStreamingAPIRequest, prepareChatHistoryForDB, generateImage, updateSessionInDB } from '../services/api.methods';
import { getImageArtgenShowPrompt, getImageAutoGenerateImage } from '../utils/configuration';
import { characters } from '../components/ChatCharacters';
import { formatDate } from '../utils/misc';

// to clarify some of params:
// editMessagePosition - this is set to index of edited message - if its null its normal, new message, if not - it is edited message
// sessionIndexForAPI, sessionIdForAPI - those are needed because we want to be sure that we're generating data for proper session (if user switches or whatever happens)
// apiAIModelName - model name that we are using for generating the message (sent to API). this will be recorded in order to show which model generated each message
const CallChatAPI = async ({
  userInput, assetInput, editMessagePosition, attachedImages, attachedFiles, sessionIndexForAPI, sessionIdForAPI, chatContentSnapshot, setChatContent, apiAIModelName, setFocusInput, setTriggerSessionAutoRename, setIsLoading, setErrorMsg, manageProgressText, mScrollToBottom, getSettings, navigate
}) => {
  setIsLoading(true);
  manageProgressText("show", "Text");
  attachedImages.map(image => image.url)

  if (config.VERBOSE_SUPERB === 1) {
    console.log("chatContent: ", chatContentSnapshot)
    console.log("attachedFiles: ", attachedFiles)
  }

  // get current character (later we will check if auto response is set)
  const currentAICharacter = chatContentSnapshot[sessionIndexForAPI].ai_character_name;
  const currentCharacter = characters.find(character => character.nameForAPI === currentAICharacter);

  // collect chat history before we do any changes to chat content snapshot
  const chatHistory = prepareChatHistoryForAPICall(chatContentSnapshot[sessionIndexForAPI].messages, editMessagePosition);
  const finalUserInput = prepareFinalUserInput(userInput, attachedImages, attachedFiles, chatHistory)

  // Add the user message to chat content
  const userMessage = {
    message: userInput,
    isUserMessage: true,
    dateGenerate: formatDate(new Date().toISOString()),
    imageLocations: attachedImages.map(image => image.url),
    fileNames: attachedFiles.map(file => file.url)
  };

  // Add or replace user message
  if (editMessagePosition === null) {
    chatContentSnapshot[sessionIndexForAPI].messages.push(userMessage);
  } else {
    // Extract the URLs from attachedImages and attachedFile
    const imageUrls = attachedImages.map(image => image.url);
    const fileUrls = attachedFiles.map(file => file.url);
    chatContentSnapshot[sessionIndexForAPI].messages[editMessagePosition.index].message = userInput;
    chatContentSnapshot[sessionIndexForAPI].messages[editMessagePosition.index].imageLocations = imageUrls;
    chatContentSnapshot[sessionIndexForAPI].messages[editMessagePosition.index].fileNames = fileUrls;
  }

  // Buffer to hold the chunks until the message is complete
  let chunkBuffer = '';
  let aiMessageIndex;

  try {
    // most characters will have autoResponse - set to true - because we want them to respond (but there are exceptions)
    if (currentCharacter.autoResponse) {
      if (editMessagePosition === null) {
        // Add a placeholder for the AI message
        const aiMessagePlaceholder = {
          message: '',
          isUserMessage: false,
          apiAIModelName: apiAIModelName,
          dateGenerate: formatDate(new Date().toISOString()),
          imageLocations: [],
          aiCharacterName: currentAICharacter
        };
        chatContentSnapshot[sessionIndexForAPI].messages.push(aiMessagePlaceholder);
        aiMessageIndex = chatContentSnapshot[sessionIndexForAPI].messages.length - 1;
      } else {
        // if its edited message - overwrite AI response
        aiMessageIndex = editMessagePosition.index + 1;
        // but if it doesn't exist - let's create it
        if (aiMessageIndex >= chatContentSnapshot[sessionIndexForAPI].messages.length) {
          const aiMessagePlaceholder = {
            message: '',
            isUserMessage: false,
            apiAIModelName: apiAIModelName,
            dateGenerate: formatDate(new Date().toISOString()),
            imageLocations: [],
            aiCharacterName: currentAICharacter
          };
          chatContentSnapshot[sessionIndexForAPI].messages.push(aiMessagePlaceholder);
        } else {
          // if exists - overwrite
          chatContentSnapshot[sessionIndexForAPI].messages[aiMessageIndex].message = '';
          chatContentSnapshot[sessionIndexForAPI].messages[aiMessageIndex].apiAIModelName = apiAIModelName;
          chatContentSnapshot[sessionIndexForAPI].messages[aiMessageIndex].dateGenerate = formatDate(new Date().toISOString());
          chatContentSnapshot[sessionIndexForAPI].messages[aiMessageIndex].aiCharacterName = currentAICharacter
        }
      }

      setChatContent(chatContentSnapshot);

      if (config.VERBOSE_SUPERB === 1) {
        console.log("API call. Final User Input", finalUserInput);
      }

      // scroll to make sure that AI message is visible
      setTimeout(() => {
        mScrollToBottom(sessionIndexForAPI, false);
      }, 1500);

      await triggerStreamingAPIRequest("chat", "text", "chat", finalUserInput, assetInput, getSettings, {
        onChunkReceived: (chunk) => {
          // if it's artgen and user disabled show prompt - don't show it
          if (currentAICharacter === "tools_artgen" && getImageArtgenShowPrompt() === false) {
            return
          }
          chunkBuffer += chunk;
          // here even though we execute setChatContent in next step - we save chunk buffers because this will be saved into DB
          chatContentSnapshot[sessionIndexForAPI].messages[aiMessageIndex].message = chunkBuffer;
          // i leave it on purpose - because it did not work this way (with snapshot)
          //setChatContent(chatContentSnapshot);
          // i needed to do functional update to update UI
          setChatContent((prevChatContent) => {
            // Make sure we update the correct session
            const updatedContent = [...prevChatContent];
            updatedContent[sessionIndexForAPI].messages[aiMessageIndex].message = chunkBuffer;
            return updatedContent;
          });
          mScrollToBottom(sessionIndexForAPI);
        },
        onStreamEnd: async (fullResponse) => {
          manageProgressText("hide", "Text");

          mScrollToBottom(sessionIndexForAPI);

          // save to DB
          const currentUserMessage = chatContentSnapshot[sessionIndexForAPI].messages[aiMessageIndex - 1];
          const currentAIResponse = chatContentSnapshot[sessionIndexForAPI].messages[aiMessageIndex];

          const finalInputForDB = prepareFinalInputForDB(sessionIdForAPI, currentUserMessage, currentAIResponse, currentAICharacter, chatContentSnapshot[sessionIndexForAPI])

          var apiCallDbMethod = "db_new_message";
          if (editMessagePosition !== null) {
            apiCallDbMethod = "db_edit_message";
          }
          await triggerAPIRequest("api/db", "provider.db", apiCallDbMethod, finalInputForDB, getSettings).then((response) => {
            if (response.success) {
              // update session in chatContent (will be useful later when switching session in top menu) and set current session id
              if (!chatContentSnapshot[sessionIndexForAPI].sessionId) {
                setChatContent((prevChatContent) => {
                  // Make sure we update the correct session
                  const updatedContent = [...prevChatContent];
                  updatedContent[sessionIndexForAPI].db_session_id = response.message.result.sessionId;
                  return updatedContent;
                });
              }

              // trigger auto rename session (only for prod) 
              // this will also trigger refresh sidebar chat sessions list (setSidebarResetTrigger - to get new session visible on the list)
              // (only once - when we have initial messages - not to keep refreshing all the time)
              if (chatContentSnapshot[sessionIndexForAPI].messages.length < 3) {
                setTriggerSessionAutoRename(response.message.result.sessionId);
              }
              if (!sessionIdForAPI) {
                // this is needed - because for example image generation is triggered later then this step - so if sessionIdForAPI is not set - it fails to update in DB
                sessionIdForAPI = response.message.result.sessionId;
                navigate(`/session/${sessionIdForAPI}`);
              }
              // update messageId in chatContent
              if (response.message.result.aiMessageId)
                currentAIResponse.messageId = response.message.result.aiMessageId;
              if (response.message.result.userMessageId)
                currentUserMessage.messageId = response.message.result.userMessageId;
            }
          });

          // TODO - test it!
          // for artgen mode - if image is enabled and no images attached - generate image
          if (currentAIResponse.aiCharacterName === "tools_artgen" && getImageAutoGenerateImage() && attachedImages.length === 0) {
            manageProgressText("show", "Image");
            try {
              console.log("Image")
              const imageLocation = await generateImage(fullResponse, getSettings);
              if (imageLocation) {
                // update chatContent with generated image
                /*setChatContent((prevChatContent) => {
                  // Make sure we update the correct session
                  const updatedContent = [...prevChatContent];
                  updatedContent[sessionIndexForAPI].messages[aiMessageIndex].imageLocations = [response.message.result];
                  return updatedContent;
                });*/
                chatContentSnapshot[sessionIndexForAPI].messages[aiMessageIndex].imageLocations = [imageLocation];
                setChatContent(chatContentSnapshot);

                manageProgressText("hide", "Image");
                mScrollToBottom(sessionIndexForAPI);
                setFocusInput(true);
                //db_update_session to DB 
                await updateSessionInDB(chatContentSnapshot[sessionIndexForAPI], sessionIdForAPI, getSettings);
              } else {
                setErrorMsg("Problem generating image");
                manageProgressText("hide", "Image");
              }
            } catch (error) {
              setIsLoading(false);
              manageProgressText("hide", "Text")
              setErrorMsg("Error during streaming. Try again.")
              console.error('Error during streaming:', error);
              console.error(error);
            } finally {
              manageProgressText("hide", "Image");
            }
          }
        }
      });
    } else { // if its message for AI character without autoResponse
      // Only send the user message to DB if autoResponse is false
      const finalInputForDB = {
        "customer_id": 1,
        "session_id": sessionIdForAPI,
        "userMessage": {
          "sender": "User",
          "message": userInput,
          "message_id": editMessagePosition !== null ? editMessagePosition.messageId : 0,
          "image_locations": attachedImages.map(image => image.url),
          "file_locations": [],
        },
        "new_ai_character_name": currentAICharacter,
        "chat_history": prepareChatHistoryForDB(chatContentSnapshot[sessionIndexForAPI])
      };

      var apiCallDbMethod = "db_new_message";
      if (editMessagePosition !== null) {
        apiCallDbMethod = "db_edit_message";
      }
      await triggerAPIRequest("api/db", "provider.db", apiCallDbMethod, finalInputForDB, getSettings).then((response) => {
        if (response.success) {
          // update sessionID (from DB) for this chat session
          if (!chatContentSnapshot[sessionIndexForAPI].sessionId) {
            setChatContent((prevChatContent) => {
              const updatedContent = [...prevChatContent];
              updatedContent[sessionIndexForAPI].db_session_id = response.message.result.sessionId;
              updatedContent[sessionIndexForAPI].ai_character_name = currentCharacter.nameForAPI;
              return updatedContent;
            });
          }
          // update current message with userMessageId
          chatContentSnapshot[sessionIndexForAPI].messages[chatContentSnapshot[sessionIndexForAPI].messages.length - 1].messageId = response.message.result.userMessageId;

          // trigger auto rename session (only for prod) 
          // this will also trigger refresh sidebar chat sessions list (setSidebarResetTrigger - to get new session visible on the list)
          // (only once - when we have initial messages - not to keep refreshing all the time)
          if (chatContentSnapshot[sessionIndexForAPI].messages.length < 2) {
            setTriggerSessionAutoRename(response.message.result.sessionId);
          }
        }
      }).catch((error) => {
        setIsLoading(false);
        manageProgressText("hide", "Text");
        setErrorMsg("Error saving message. Try again.");
        console.error('Error saving message:', error);
      });
    }

    setIsLoading(false);
    manageProgressText("hide", "Text");
    setFocusInput(true);


    const original_ai_character = chatContentSnapshot[sessionIndexForAPI].original_ai_character
    // fallback to original AI character (after single use of different one)
    if (original_ai_character !== "") {
      setChatContent((prevChatContent) => {
        const updatedChatContent = [...prevChatContent];
        updatedChatContent[sessionIndexForAPI].ai_character_name = original_ai_character
        updatedChatContent[sessionIndexForAPI].original_ai_character = "";
        return updatedChatContent;
      });
    }
  } catch (error) {
    setIsLoading(false);
    manageProgressText("hide", "Text")
    setErrorMsg("Error during streaming. Try again.")
    console.error('Error during streaming:', error);
  }

}

// collect chat history (needed to send it API to get whole context of chat)
// (excluding the latest message - as this will be sent via userPrompt), including images if any
// or excluding 1 or 2 last messages - if its edited user message
const prepareChatHistoryForAPICall = (messages, editMessagePosition) => {
  var chatHistory = messages;

  if (editMessagePosition !== null) {
    // if it is edited message - we have to drop 2 last messages (user and AI response)
    // but only if it is not the last message in chat
    if (editMessagePosition.index === chatHistory.length - 1) {
      chatHistory = chatHistory.slice(0, -1);
    } else {
      chatHistory = chatHistory.slice(0, -2);
    }
  }
  return chatHistory;
}

const prepareFinalUserInput = (userInput, attachedImages, attachedFiles, chatHistory) => {
  const finalUserInput = {
    "prompt": [
      { "type": "text", "text": userInput },
      ...attachedImages.map(image => ({ "type": "image_url", "image_url": { "url": image.url } })),
      ...attachedFiles.map(file => ({ "type": "file_url", "file_url": { "url": file.url } })),
    ],
    "chat_history": (chatHistory.map((message) => ({
      "role": message.isUserMessage ? "user" : "assistant",
      "content": [
        { "type": "text", "text": message.message },
        ...(message.imageLocations || []).map(imageUrl => ({ "type": "image_url", "image_url": { "url": imageUrl } })),
        ...(message.fileNames || []).map(url => ({ "type": "file_url", "file_url": { "url": url } }))
      ]
    }))),
  };
  return finalUserInput;
}

const prepareFinalInputForDB = (sessionIdForAPI, currentUserMessage, currentAIResponse, currentAICharacter, chatContentForSession) => {
  const finalInputForDB = {
    "customer_id": 1,
    "session_id": sessionIdForAPI,
    "userMessage": {
      "sender": "User",
      "message": currentUserMessage.message,
      "message_id": currentUserMessage.messageId || 0,
      "image_locations": currentUserMessage.imageLocations || [],
      "file_locations": currentUserMessage.fileNames || [],
    },
    "aiResponse": {
      "sender": "AI",
      "message": currentAIResponse.message,
      "message_id": currentAIResponse.messageId || 0,
      "image_locations": currentAIResponse.imageLocations || [],
      "file_locations": currentAIResponse.fileNames || [],
    },
    "new_ai_character_name": currentAICharacter,
    "chat_history": prepareChatHistoryForDB(chatContentForSession)
  }
  return finalInputForDB;
}

export default CallChatAPI;
